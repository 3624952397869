<template>
  <h1>Redirecting...</h1>
</template>

<script>
import client from '@/helpers/pocketbase'

export default {
  name: 'Redirect',
  computed: {
    loginState() {
      return this.$store.getters['global/getLoginState']
    },
  },
  async mounted() {
    try {
      const params = new URL(window.location).searchParams

      // compare the redirect's state param and the stored provider's one
      if (this.loginState.state !== params.get('state')) {
        throw "State parameters don't match."
      }

      //       {
      //     "email": "nfortune03@gmail.com",
      //     "name": "Nicholas Fortune",
      //     "image": "https://lh3.googleusercontent.com/a/ALm5wu2KCloC3MHLhkRyQuYjfCT-LqNy8zRDC8Rk-gmdQQ=s96-c",
      //     "token": "eyJhbGciOiJSUzI1NiIsImtpZCI6Ijk1NTEwNGEzN2ZhOTAzZWQ4MGM1NzE0NWVjOWU4M2VkYjI5YjBjNDUiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiOTEyMTEwNDYzNTMtNnJhZDJ0Z3ZzOGRsazQwdHA2YTA0cTBkYzFnM2tlb2UuYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJhdWQiOiI5MTIxMTA0NjM1My02cmFkMnRndnM4ZGxrNDB0cDZhMDRxMGRjMWcza2VvZS5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjEwOTc2NTA0MjQxNzI0NDY3MjExMSIsImVtYWlsIjoibmZvcnR1bmUwM0BnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXRfaGFzaCI6ImRZZ2N1Q0pSd0twWW5DYmxSNGNYV2ciLCJuYW1lIjoiTmljaG9sYXMgRm9ydHVuZSIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BTG01d3UyS0Nsb0MzTUhMaGtSeVF1WWpmQ1QtTHFOeTh6UkRDOFJrLWdtZFFRPXM5Ni1jIiwiZ2l2ZW5fbmFtZSI6Ik5pY2hvbGFzIiwiZmFtaWx5X25hbWUiOiJGb3J0dW5lIiwibG9jYWxlIjoiaWQiLCJpYXQiOjE2NjU3MjM4MzksImV4cCI6MTY2NTcyNzQzOSwianRpIjoiNmJjNjUxY2ZmNzYxOTM5NzJhM2MxOWRlOTlhY2U3NzZkNzY4NWJmYyJ9.QLOm3D0Iw9LXbjHgJEuSxVmTuHnAAN0QMXp18Ft9u15LoWr7Ao1pcZL4-EdJSPmQr-syCru9yPhpKuSr2Z38IVtYNHe2EW_lAokh3NS0YJXASHJGQClGlEMqmO9iBt2uBPo5lYK_MezAqJPmApiPJz9cJCqfO6INnEib38gaROtCDJ6MuxqyAklTUiULcomDqp3q4LkdBfG6jeSotpNaStlhyZwpTIUefnybMTOhpGUOqYMbmCbsm1a-u6QEslyYh_5tSZjVMXXu5YcMGE-PQBerQo59vxUf3fRajOxtCt269gBciA9I8fcb9_Z8IpTCpFiee08d5qfVnP9VPZQKig"
      // }

      //       {
      //     "meta": {
      //         "id": "109765042417244672111",
      //         "name": "Nicholas Fortune",
      //         "username": "",
      //         "email": "nfortune03@gmail.com",
      //         "avatarUrl": "https://lh3.googleusercontent.com/a/ALm5wu2KCloC3MHLhkRyQuYjfCT-LqNy8zRDC8Rk-gmdQQ=s96-c"
      //     },
      //     "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NjY5MzM0ODUsImlkIjoicndid3JlN2ptZTcxMHIzIiwidHlwZSI6InVzZXIifQ.5CSQNV_tvIzjdb-5ASIgoWHoX2hFYn6IhYJ4EoEO3wY",
      //     "user": {
      //         "id": "rwbwre7jme710r3",
      //         "created": "2022-10-14 05:01:50.647",
      //         "updated": "2022-10-14 05:01:50.647",
      //         "email": "nfortune03@gmail.com",
      //         "verified": true,
      //         "lastResetSentAt": "",
      //         "lastVerificationSentAt": "",
      //         "profile": {
      //             "id": "uoddba5cx93jtu6",
      //             "created": "2022-10-14 05:01:50.647",
      //             "updated": "2022-10-14 05:01:50.647",
      //             "@collectionId": "aUBPwS0zfTm8shr",
      //             "@collectionName": "profiles",
      //             "avatar": "",
      //             "name": "",
      //             "userId": "rwbwre7jme710r3",
      //             "@expand": {}
      //         }
      //     }
      // }
      // authenticate
      const { token, user, meta } = await client.users.authViaOAuth2(
        this.loginState.name,
        params.get('code'),
        this.loginState.codeVerifier,
        `${window.location.origin}/login/redirect`,
      )
      const userPayload = {
        email: user.email,
        name: meta.name,
        image: meta.avatarUrl,
        token,
        skip_oauth2: true,
      }
      this.$store.commit('auth/setUserState', userPayload)
      this.$router.push({ name: 'login' })

      // //console.log('🚀 ~ file: Redirect.vue ~ line 29 ~ mounted ~ test', token, user, meta)
    } catch (err) {
      //console.log('🚀 ~ file: Redirect.vue ~ line 26 ~ mounted ~ err', err)
    }
    this.$store.commit('global/setLoginState', null)
  },
}
</script>

<style>
</style>
